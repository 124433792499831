import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import Divider from "@material-ui/core/Divider";
import {gql, useMutation, useQuery} from "@apollo/client";
import MuiAlert, {AlertProps} from "@material-ui/lab/Alert";
import {useTranslation} from "react-i18next";
import {Building, MeetingRoomEquipment} from "../../API";
import MeetingRoomEquipmentManagerBuildingDropdown from "./MeetingRoomEquipmentManagerBuildingDropdown";
import {usePermissionHelper} from "../../hooks/usePermissionHelper";
import {listMeetingRoomEquipments} from "../../graphql/queries";
import {useMainApplicationContext} from "../../hooks/useMainApplicationContext";
import Sit2GetherCloseIcon from "../../styles/Sit2GetherCloseIcon";
import Sit2GetherAddIcon from "../../styles/Sit2GetherAddIcon";
import CreateOrUpdateMeetingRoomEquipmentDialog from "./CreateOrUpdateMeetingRoomEquipmentDialog";
import DeleteEntryConfirmationDialogComponent from "../DeleteEntryConfirmationDialogComponent";
import {deleteSecureMeetingRoomEquipment} from "../../graphql/mutations";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Edit from "@material-ui/icons/Edit";
import DeleteButton from "../Buttons/DeleteButton";
import CloseButton from "../Buttons/CloseButton";
import {useDeviceMediaType} from "../../hooks/useDeviceMediaType";

export interface MeetingRoomEquipmentManagerProps {
    showMeetingRoomEquipmentManager: boolean
    setShowMeetingRoomEquipmentManager: (value: boolean) => void
}

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MeetingRoomEquipmentManager: React.FC<MeetingRoomEquipmentManagerProps> = (props) => {
    let {showMeetingRoomEquipmentManager, setShowMeetingRoomEquipmentManager} = props
    let {
        buildingList
    } = useMainApplicationContext()
    const [selectedEquipment, setSelectedEquipment] = useState<MeetingRoomEquipment | undefined>(undefined)
    const [showCreateEquipmentDialog, setShowCreateEquipmentDialog] = useState<boolean>(false)
    const [showDeleteEquipmentDialog, setShowDeleteEquipmentDialog] = useState<boolean>(false)
    const [isShowCreationOrUpdateAlert, setIsShowCreationOrUpdateAlert] = useState(false)
    const [isShowDeletionAlert, setIsShowDeletionAlert] = useState(false)
    const {t} = useTranslation();
    const [selectedBuilding, setSelectedBuilding] = useState<Building | undefined>(undefined)
    const [equipmentOfSelectedBuilding, setEquipmentOfSelectedBuilding] = useState<MeetingRoomEquipment[]>([]);

    const {refetch} = useQuery(gql(listMeetingRoomEquipments))
    const [deleteEquipmentMutation] = useMutation(gql(deleteSecureMeetingRoomEquipment))
    const {isNoFullscreen, isMobile} = useDeviceMediaType()

    const handleCreateEquipment = () => {
        setSelectedEquipment(undefined)
        setShowCreateEquipmentDialog(true)
    }

    useEffect(() => {
        if (selectedBuilding || !showCreateEquipmentDialog || !showDeleteEquipmentDialog) {
            refetch({filter: {buildingId: {eq: selectedBuilding?.buildingId}}})
                .then(({data}) => {
                    let equipment = [...data?.listMeetingRoomEquipments?.items];
                    if (equipment) {
                        setEquipmentOfSelectedBuilding(() => {
                                return equipment.sort((a: MeetingRoomEquipment, b: MeetingRoomEquipment) => a.name.localeCompare(b.name));
                            }
                        );
                    }
                })
                .catch((err) => console.error(err + " Error refetching Equipment Data in MeetingRoomEquipmentManager"));
        }
    }, [selectedBuilding, showCreateEquipmentDialog, showDeleteEquipmentDialog]);

    const handleDeleteEquipment = () => {
        if (selectedEquipment) {
            deleteEquipmentMutation({
                variables: {
                    input: {
                        equipmentId: selectedEquipment?.equipmentId
                    }
                }
            })
                .then(() => setShowDeleteEquipmentDialog(false))
                .then(() => setSelectedEquipment(undefined))
                .catch(err => {
                    console.error(err + " Error deleting Equipment data in deleteEquipmentMutation");
                    setShowDeleteEquipmentDialog(false);
                    setSelectedEquipment(undefined);
                    showErrorMessage();
                })
        }
    }

    const showErrorMessage = () => {
        setIsShowDeletionAlert(true)
        setTimeout(() => {
            setIsShowDeletionAlert(false)
        }, 4000)
    }

    return (
        <Dialog style={{width: "100%"}} open={showMeetingRoomEquipmentManager}>
            {isShowCreationOrUpdateAlert &&
                <Alert severity="error">{t("equipment_mngmnt_error_create_equipment")}</Alert>}
            {isShowDeletionAlert && <Alert severity="error">{t("equipment_mngmnt_error_delete_equipment")}</Alert>}
            <DialogTitle>
                {t("equipment_mngmnt_dialog-title")}
            </DialogTitle>
            <DialogContent>
                <MeetingRoomEquipmentManagerBuildingDropdown
                    buildings={buildingList}
                    showMeetingRoomEquipmentManager={showMeetingRoomEquipmentManager}
                    setSelectedBuilding={setSelectedBuilding}
                />

                {showCreateEquipmentDialog && selectedBuilding &&
                    <CreateOrUpdateMeetingRoomEquipmentDialog
                        setShowCreateMeetingRoomEquipmentDialog={setShowCreateEquipmentDialog}
                        setIsShowAlert={setIsShowCreationOrUpdateAlert}
                        selectedBuilding={selectedBuilding}
                        selectedEquipment={selectedEquipment}
                        setSelectedEquipment={setSelectedEquipment}
                        equipmentOfSelectedBuilding={equipmentOfSelectedBuilding}/>
                }
                {showDeleteEquipmentDialog && selectedEquipment && <DeleteEntryConfirmationDialogComponent
                    isShowDeleteDialog={showDeleteEquipmentDialog}
                    handleDelete={handleDeleteEquipment}
                    setIsShowDeleteDialog={setShowDeleteEquipmentDialog}
                />}

                <TableContainer style={{maxHeight: "440px", marginTop: "1rem"}}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('general_equipment-singular')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectedBuilding ?
                                equipmentOfSelectedBuilding.map((item: MeetingRoomEquipment, index: number) => (
                                    <TableRow
                                        key={item.equipmentId}
                                        selected={selectedEquipment === item}
                                        onClick={() => setSelectedEquipment(item)}
                                    >
                                        <TableCell>
                                            {item.name}
                                        </TableCell>
                                    </TableRow>
                                )) : null
                            }
                        </TableBody>

                    </Table>
                </TableContainer>
            </DialogContent>

            <Divider/>

            <DialogActions className={isNoFullscreen ? "dialogActionsNoFullScreen" : "dialogActions"}>
                <CloseButton
                    style={{visibility: 'hidden', display: isNoFullscreen ? 'none' : 'flex'}}
                    data-testid={"btn-close-hidden"}
                ></CloseButton>
                <div style={{flex: "1 1 auto"}}></div>
                <Button variant={"contained"}
                        color={"primary"}
                        startIcon={!isMobile && <Sit2GetherAddIcon/>}
                        size={isMobile ? 'small' : 'medium'}
                        onClick={handleCreateEquipment}
                        data-testid={"btn-create"}
                >
                    {t("equipment_mngmnt_add_button-text")}
                </Button>
                <Button variant={"contained"}
                        color={"primary"}
                        startIcon={!isMobile && <Edit/>}
                        size={isMobile ? 'small' : 'medium'}
                        disabled={!selectedEquipment}
                        onClick={() => setShowCreateEquipmentDialog(true)}
                        data-testid={"btn-edit"}
                >
                    {t("equipment_mngmnt_edit_button-text")}
                </Button>
                <DeleteButton
                    disabled={!selectedEquipment}
                    size={isMobile ? 'small' : 'medium'}
                    onClick={() => setShowDeleteEquipmentDialog(true)}
                    data-testid={"btn-delete"}>
                    {t('delete')}
                </DeleteButton>
                <div style={{flex: "1 1 auto"}}></div>
                <CloseButton
                    size={isMobile ? 'small' : 'medium'}
                    onClick={() => setShowMeetingRoomEquipmentManager(false)}
                    data-testid={"btn-close"}
                ></CloseButton>
            </DialogActions>
        </Dialog>

    )
}
export default MeetingRoomEquipmentManager;
