import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {Inventory} from "../API";
import Divider from "@material-ui/core/Divider";
import Edit from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import {gql, useMutation} from "@apollo/client";
import {deleteSecureInventory as deleteInvTemplate} from "../graphql/mutations";
import CreateInventoryComponent from "./CreateInventoryComponent";
import MuiAlert, {AlertProps} from "@material-ui/lab/Alert";
import {InvType} from "../Utils/Enums";
import RoomManagerAdminForm from "./RoomManager/RoomManagerAdminForm";
import {useMainApplicationContext,} from "../hooks/useMainApplicationContext";
import {useErrorContext} from "../hooks/useErrorContext";
import {useTranslation} from "react-i18next";
import Sit2GetherAddIcon from "../styles/Sit2GetherAddIcon";
import DeleteEntryConfirmationDialogComponent from "./DeleteEntryConfirmationDialogComponent";
import CloseButton from "./Buttons/CloseButton";
import DeleteButton from "./Buttons/DeleteButton";
import {t} from "i18next";
import {useDeviceMediaType} from "../hooks/useDeviceMediaType";

export interface InventoryProps {
    showInventoryManager: boolean
    setShowInventoryManager: (value: boolean) => void
    inventories: [] | Inventory []
    type: InvType
}

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const InventoryManagerComponent: React.FC<InventoryProps> = (props) => {
    let {showInventoryManager, setShowInventoryManager, inventories, type} = props
    let {
        orgUnitList,
        adminOrgUnitList,
        selectedOrgUnit,
        setSelectedOrgUnit,
        currentUser,
        rooms
    } = useMainApplicationContext()
    const {reportError} = useErrorContext();
    const [selectedInv, setSelectedInv] = useState<Inventory | undefined>(undefined)
    const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false)
    const [isShowCreateInv, setIsShowCreateInv] = useState(false)
    const [isShowAlert, setIsShowAlert] = useState(false)

    const [deleteInvMutation] = useMutation(gql(deleteInvTemplate))

    const {isNoFullscreen, isMobile} = useDeviceMediaType()

    useEffect(() => {
        if (showInventoryManager) {
            const sortedOwnOrgUnits = adminOrgUnitList.filter(org => !org.deleted)
            setSelectedOrgUnit(sortedOwnOrgUnits[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showInventoryManager]);

    const handleDeleteInv = () => {
        deleteInvMutation({
            variables: {
                input: {
                    inventoryId: selectedInv!.inventoryId
                }
            }
        })
            .then(() => setIsShowDeleteDialog(false))
            .then(() => setSelectedInv(undefined))
            .catch(err => reportError(err, "", "InventoryManagerComponent handleDeleteInv "))
    }

    const handleCreateInv = () => {
        setSelectedInv(undefined)
        setIsShowCreateInv(true)
    }

    const {t} = useTranslation();
    const handleTitles = (shortVersion: boolean) => {
        if (shortVersion) {
            switch (type) {
                case "Dockingstation":
                    return t("docking_station-column")
                case "Monitor":
                    return t("monitor-column")
            }
        } else {
            switch (type) {
                case "Dockingstation":
                    return t("docking_mngmnt_dialog-title")
                case "Monitor":
                    return t("monitor_mngmnt_dialog-title")
            }
        }
        return ""
    }

    return (
        <Dialog style={{width: "100%"}} open={showInventoryManager} data-testid={"inventoryManager"}>
            {isShowAlert && <Alert severity="error">{t("inv_mngmnt_error_create_inv")}</Alert>}
            <DialogTitle>
                {handleTitles(false)}
            </DialogTitle>
            <Box>
                <DialogContent>
                    {currentUser.isAdmin || currentUser.isOrgUnitAdmin ?
                        <RoomManagerAdminForm rooms={rooms}/>
                        :
                        <div data-testid={"org-unit-label"}>{t('daily_bookings_table_orgunit_column')}
                            <div
                                data-testid={"org-unit-label-content"}>{orgUnitList[0]?.orgName}
                            </div>
                        </div>
                    }
                    {isShowDeleteDialog && <DeleteEntryConfirmationDialogComponent
                        isShowDeleteDialog={isShowDeleteDialog}
                        handleDelete={handleDeleteInv}
                        setIsShowDeleteDialog={setIsShowDeleteDialog}
                    />}
                    {isShowCreateInv &&
                        <CreateInventoryComponent setIsShowCreateInv={setIsShowCreateInv} type={type}
                                                  selectedInv={selectedInv} setSelectedInv={setSelectedInv}
                                                  setIsShowAlert={setIsShowAlert}/>}

                    <TableContainer style={{maxHeight: "440px"}}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell data-testid={"inventory-column"}>{handleTitles(true)}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody data-testid={"inventory-list"}>
                                {inventories.filter(inventory => inventory.orgUnitId === selectedOrgUnit?.orgId).map((item: Inventory, index: number) => (
                                    <TableRow
                                        data-testid={`inventory-${index}`}
                                        key={item.inventoryId} selected={selectedInv === item}
                                        onClick={() => setSelectedInv(item)}>
                                        <TableCell>
                                            {item.name}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>

                <Divider/>
            </Box>
            <DialogActions className={isNoFullscreen ? "dialogActionsNoFullScreen" : "dialogActions"}>
                <CloseButton
                    style={{visibility: 'hidden', display: isNoFullscreen ? 'none' : 'flex'}}
                    data-testid={"btn-close-hidden"}>
                </CloseButton>
                <div style={{flex: "1 1 auto"}}></div>
                <Button variant={"contained"}
                        color={"primary"}
                        startIcon={!isMobile && <Sit2GetherAddIcon/>}
                        size={isMobile ? 'small' : 'medium'}
                        onClick={() => handleCreateInv()}
                        data-testid={"btn-create"}
                >
                    {t("inv_mngmnt_add_button-text")}
                </Button>
                <Button variant={"contained"}
                        color={"primary"}
                        startIcon={!isMobile && <Edit/>}
                        disabled={!selectedInv}
                        size={isMobile ? 'small' : 'medium'}
                        onClick={() => setIsShowCreateInv(true)}
                        data-testid={"btn-edit"}
                >
                    {t("inv_mngmnt_edit_button-text")}
                </Button>
                <DeleteButton
                    disabled={!selectedInv}
                    size={isMobile ? 'small' : 'medium'}
                    onClick={() => setIsShowDeleteDialog(true)}
                    data-testid={"btn-delete"}>
                    {t('delete')}
                </DeleteButton>
                <div style={{flex: "1 1 auto"}}></div>
                <CloseButton
                    size={isMobile ? 'small' : 'medium'}
                    onClick={() => setShowInventoryManager(false)}
                    data-testid={"btn-close"}>
                </CloseButton>
            </DialogActions>
        </Dialog>

    )
}
export default InventoryManagerComponent;
