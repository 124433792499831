import React, {FC, useContext, useEffect, useMemo, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import DayPickCalendarComponent from "../DayPickCalendarComponent";
import {Building, MeetingRoom, MeetingRoomEquipment, Room} from "../../API";
import MeetingRoomBookingManagerListComponent from "./MeetingRoomBookingManagerListComponent";
import MeetingRoomBookingEditComponent from "./MeetingRoomBookingEditComponent";
import {MeetingRoomBookingListItem} from "../../types/MeetingRoomBookingListItemType";
import Divider from "@material-ui/core/Divider";
import MeetingRoomBookingMatrixComponent from "./MeetingRoomBookingMatrixComponent";
import MainApplicationContext from "../../context/MainApplicationContext";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Skeleton from "@mui/material/Skeleton";
import DeleteEntryConfirmationDialogComponent from "../DeleteEntryConfirmationDialogComponent";
import {gql, useMutation, useQuery} from "@apollo/client";
import {deleteSecureMeetingRoomBooking} from "../../graphql/mutations";
import {useErrorContext} from "../../hooks/useErrorContext";
import {createNewTodayDateWithoutHours} from "../../services/DateUtils";
import {useMeetingRoomBookingsOfMeetingRoomsByDate} from "../../hooks/useMeetingRoomBookingsOfMeetingRoomsByDate";
import MeetingRoomBookingSelectorsComponent from "./MeetingRoomBookingSelectorsComponent";
import Edit from "@material-ui/icons/Edit";
import {useDeviceMediaType} from "../../hooks/useDeviceMediaType";
import {MeetingRoomWithLevelP} from "../../types/PermissionHandling";
import {adjustManagableStateByNeighborhood} from "../../Utils/Roles";
import {MeetingRoomType} from "../../Utils/Enums";
import {listMeetingRoomEquipments} from "../../graphql/queries";
import DeleteButton from "../Buttons/DeleteButton";
import CloseButton from "../Buttons/CloseButton";

export interface MeetingRoomBookingManagerProps {
    showMeetingRoomBookingManagerComponent: boolean
    setShowMeetingRoomBookingManagerComponent: (value: boolean) => void
}

export interface Selection {
    building: Building | undefined,
    roomPlans: Room[] | undefined,
    meetingRooms: MeetingRoom[] | undefined
    types: string[] | undefined
}

const MeetingRoomBookingManagerComponent: FC<MeetingRoomBookingManagerProps> = (props) => {
    const {
        showMeetingRoomBookingManagerComponent,
        setShowMeetingRoomBookingManagerComponent,
    } = props

    const {reportError} = useErrorContext();
    const {neighborhoods, currentUser, userSettingsObject} = useContext(MainApplicationContext);
    const defaultRoomConfig = userSettingsObject?.userSettings?.defaultRoomConfig;
    const [deleteMeetingRoomBookingRequest] = useMutation(gql(deleteSecureMeetingRoomBooking));
    const [date, setDate] = useState<Date>(createNewTodayDateWithoutHours());
    const [selectedBooking, setSelectedBooking] = useState<MeetingRoomBookingListItem | null>(null);
    const [idOfHighlightedBooking, setIdOfHighlightedBooking] = useState<string | null>(null);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showDeletionDialog, setShowDeletionDialog] = useState(false);

    const {t} = useTranslation();

    const {shouldAlignButtons, isNoFullscreen, isMobile} = useDeviceMediaType()

    const [selectedRoomPlans, setSelectedRoomPlans] = useState<Room[] | undefined>(undefined);
    const [displayedMeetingRooms, setDisplayedMeetingRooms] = useState<MeetingRoomWithLevelP[]>([]);
    const [meetingRoomBookingsWithoutManagementInfo, isLoading, refetchBookings] = useMeetingRoomBookingsOfMeetingRoomsByDate(
        date, currentUser, displayedMeetingRooms);
    const [meetingRoomBookings, setMeetingRoomBookings] = useState<MeetingRoomBookingListItem[]>([]);
    const [isNeighborhoodFiltered, setIsNeighborhoodFiltered] = useState<boolean>(true);
    const [typeFilter, setTypeFilter] = useState<string>("");
    const [isTypeFiltered, setIsTypeFiltered] = useState<boolean>(false);
    const [meetingRoomMinCapacity, setMeetingRoomMinCapacity] = useState(0);
    const isCapacityFiltered = meetingRoomMinCapacity > 0;
    const [buildingEquipment, setBuildingEquipment] = useState<MeetingRoomEquipment[]>([])
    const buildingEquipmentMap = useMemo(() => {
        const map = new Map<string, MeetingRoomEquipment>()
        buildingEquipment.forEach(equip => map.set(equip.equipmentId, equip))
        return map;
    }, [buildingEquipment])
    const [filteredEquipment, setFilteredEquipment] = useState<MeetingRoomEquipment[]>([]);
    const isEquipmentFiltered = filteredEquipment.length > 0;
    const [selection, setSelection] = useState<Selection>({
        building: undefined,
        roomPlans: undefined,
        meetingRooms: undefined,
        types: undefined
    });

    const {refetch: fetchEquipmentQuery} = useQuery(gql(listMeetingRoomEquipments))

    useEffect(() => {
        setMeetingRoomBookings(meetingRoomBookingsWithoutManagementInfo.map(booking => {
            const meetingRoom = displayedMeetingRooms.find(mr => mr.meetingRoomId === booking.meetingRoomId);

            if (!meetingRoom || !meetingRoom.neighborhoodId) {
                return booking;
            }

            const neighborhoodsOfSelectedMeetingRoom = neighborhoods.filter(n => meetingRoom.roomId === n.roomId);

            return adjustManagableStateByNeighborhood(booking, meetingRoom?.neighborhoodId, neighborhoodsOfSelectedMeetingRoom, currentUser.admin2GetherRoles)
        }))
    }, [meetingRoomBookingsWithoutManagementInfo, displayedMeetingRooms, neighborhoods, currentUser])

    const getFilteredMeetingRooms = (meetingRooms: MeetingRoomWithLevelP[]) => {
        let filteredMeetingRooms: MeetingRoomWithLevelP[] = [];
        if (isNeighborhoodFiltered && isTypeFiltered) {
            const meetingRoomsByNeighborhoodType = getFilteredMeetingRoomsByNeighborhoodType(meetingRooms);
            filteredMeetingRooms = getFilteredMeetingRoomsByMeetingRoomType(meetingRoomsByNeighborhoodType);
        } else if (isTypeFiltered) {

            filteredMeetingRooms = getFilteredMeetingRoomsByMeetingRoomType(meetingRooms);
        } else if (isNeighborhoodFiltered) {
            filteredMeetingRooms = getFilteredMeetingRoomsByNeighborhoodType(meetingRooms);
        } else {
            filteredMeetingRooms = meetingRooms;
        }

        filteredMeetingRooms = filteredMeetingRooms.filter(mr => mr.capacity && mr.capacity >= meetingRoomMinCapacity);

        if (!filteredEquipment.length) return filteredMeetingRooms;
        filteredMeetingRooms = filteredMeetingRooms.filter(meetingRoom =>
            testForEquipmentFulfillment(meetingRoom.equipmentIds ?? [], filteredEquipment.map(eq => eq.equipmentId)))
        return filteredMeetingRooms;

        function testForEquipmentFulfillment(equipInRoom: string[], filters: string[]): boolean {
            const room = [...equipInRoom];
            const filter = [...filters];
            while (filter.length > 0) {
                const index = room.indexOf(filter[0]);
                if (index === -1) return false
                room.splice(index, 1);
                filter.splice(0, 1)
            }
            return true
        }
    }

    const getFilteredMeetingRoomsByNeighborhoodType = (meetingRooms: MeetingRoomWithLevelP[]) => {
        const defaultNeighborhoodId = defaultRoomConfig?.neighborhoodId
        if (defaultNeighborhoodId) {
            return meetingRooms.filter(meetingRoom => {
                return meetingRoom.neighborhoodId === defaultNeighborhoodId
                    || meetingRoom.type !== MeetingRoomType.NEIGHBORHOOD;
            })
        }
        return meetingRooms.filter(meetingRoom => meetingRoom.type !== MeetingRoomType.NEIGHBORHOOD);
    }

    const getFilteredMeetingRoomsByMeetingRoomType = (meetingRooms: MeetingRoomWithLevelP[]) => {
        switch (typeFilter) {
            case t('meetingroom_type_classroom'):
                return meetingRooms.filter(meetingRoom => meetingRoom.type === MeetingRoomType.CLASSROOM)

            case t('meetingroom_type_internal'):
                return meetingRooms.filter(meetingRoom => meetingRoom.type === MeetingRoomType.INTERNAL)

            case t('meetingroom_type_neighborhood'):
                return meetingRooms.filter(meetingRoom => meetingRoom.type === MeetingRoomType.NEIGHBORHOOD)

            case t('meetingroom_type_visitors_center'):
                return meetingRooms.filter(meetingRoom => meetingRoom.type === MeetingRoomType.VISITORS_CENTER)

            default:
                return meetingRooms;
        }
    }

    const getFilteredMeetingRoomBookings = (meetingRoomBookings: MeetingRoomBookingListItem[], meetingRooms: MeetingRoomWithLevelP[]) => {
        const meetingRoomIds = meetingRooms.map(meetingRoom => meetingRoom.meetingRoomId)
        return meetingRoomBookings.filter(booking => meetingRoomIds.includes(booking.meetingRoomId));
    }

    const filteredMeetingRooms = useMemo(() => getFilteredMeetingRooms(displayedMeetingRooms),
        [displayedMeetingRooms, isNeighborhoodFiltered, typeFilter, filteredEquipment, meetingRoomMinCapacity]);

    const filteredMeetingRoomBookings =
        useMemo(() => getFilteredMeetingRoomBookings(meetingRoomBookings, filteredMeetingRooms),
            [meetingRoomBookings, filteredMeetingRooms]);

    function onRoomPlansSelect(roomPlans: Room[]) {
        setSelectedRoomPlans(roomPlans);
    }

    async function fetchBuildingEquipment(building: Building | undefined) {
        if (!building) return;
        await fetchEquipmentQuery({
            filter: {
                buildingId: {
                    eq: building?.buildingId
                }
            }
        }).then(result => setBuildingEquipment(result.data.listMeetingRoomEquipments.items ?? []))
            .catch(error => console.error("Error while fetching building equipment ", error))

    }

    useEffect(() => {
        fetchBuildingEquipment(selection.building)
    }, [selection.building]);

    useEffect(function deselectBooking() {
        setSelectedBooking(null)
    }, [date]);

    useEffect(() => {
        if (showMeetingRoomBookingManagerComponent) {
            refetchBookings()
        }
    }, [showMeetingRoomBookingManagerComponent])

    function handleCloseMeetingRoomBookingManagerDialog() {
        setShowMeetingRoomBookingManagerComponent(false);
        setDate(createNewTodayDateWithoutHours());
    }

    function startEditing() {
        if (!selectedBooking) {
            return;
        }
        if (!selectedBooking.managingAllowed) {
            return;
        }
        setShowEditDialog(true);
    }

    function editFromMatrix(item: MeetingRoomBookingListItem) {
        if (canEditBooking(item)) {
            //const {neighborhoodName, meetingRoomName, meetingRoomType, meetingRoomCapacity, ...booking} = item
            setSelectedBooking(item);
            setShowEditDialog(true);
        }
    }

    function canEditBooking(item: MeetingRoomBookingListItem) {
        return item.managingAllowed;
    }

    function handleBookingDeletionAttempt() {
        if (!selectedBooking) {
            return;
        }
        setShowDeletionDialog(true)
    }

    function handleOnDeleteBooking() {
        deleteMeetingRoomBookingRequest({
            variables: {
                input: {
                    bookingId: selectedBooking?.bookingId,
                    bookerId: selectedBooking?.bookerId,
                    orgUnitId: selectedBooking?.orgUnitId,
                    meetingRoomId: selectedBooking?.meetingRoomId,
                    roomId: selectedBooking?.roomId,
                    date: selectedBooking?.date
                }
            }
        })
            .then(() => setShowDeletionDialog(false))
            .then(() => setShowEditDialog(false))
            .then(() => refetchBookings())
            .catch((error) => reportError(error, error.message, "MeetingRoomBookingManagerComponent deleteSelectedMeetingRoomBooking"))
    }

    function handleOnClickAway() {
        if (!showEditDialog && !showDeletionDialog) {
            setSelectedBooking(null);
        }
    }

    function isFiltered(): boolean {
        return isNeighborhoodFiltered || isTypeFiltered || isEquipmentFiltered || isCapacityFiltered
    }

    //parameter store
    const ps = {
        MtngRoomBkngEditProps: {
            hideDeleteButton: false,
            bookingToEdit: selectedBooking!,
            allBookings: meetingRoomBookings,
            refetchBookings: refetchBookings,
            setShowEditDialog: setShowEditDialog,
            show: showEditDialog,
            onAttemptDeleteBooking: handleBookingDeletionAttempt
        },
        dialogProps: {
            open: showMeetingRoomBookingManagerComponent,
            fullWidth: true,
            maxWidth: false as false
        },
        mtngRoomBkngMtrxProps: {
            bookingListItems: isFiltered() ? filteredMeetingRoomBookings : meetingRoomBookings,
            meetingRoomsWithLevel: isFiltered() ? filteredMeetingRooms : displayedMeetingRooms,
            rooms: selectedRoomPlans ?? [],
            building: selection.building,
            setIdOfHighlightedBooking: setIdOfHighlightedBooking,
            editBooking: editFromMatrix,
            currentUser: currentUser,
            buildingEquipment: buildingEquipmentMap,
            refetchBookings: refetchBookings,
            date: date,
        },
        DltEntryCofrmDlogProps: {
            isShowDeleteDialog: showDeletionDialog,
            handleDelete: handleOnDeleteBooking,
            setIsShowDeleteDialog: setShowDeletionDialog,
        },
        bkngListProps: {
            bookingListItems: isFiltered() ? filteredMeetingRoomBookings : meetingRoomBookings,
            neighborhoods: neighborhoods,
            onEditBooking: startEditing,
            onAttemptDeleteBooking: handleBookingDeletionAttempt,
            selectedBooking: selectedBooking,
            setSelectedBooking: setSelectedBooking,
            idOfHighlightedBooking: idOfHighlightedBooking,
        },
        btnProps: {
            onClick: handleCloseMeetingRoomBookingManagerDialog,
        }
    }

    const listAndMatrixLoading = isLoading;

    return <>
        <MeetingRoomBookingEditComponent {...ps.MtngRoomBkngEditProps}/>
        <Dialog data-testid={"meeting-room-booking-manager-test-id"} {...ps.dialogProps} >

            <DialogTitle>{t("meeting_room_booking_manager_dialog-title")}</DialogTitle>


            <DialogContent>

                <MeetingRoomBookingSelectorsComponent
                    setSelectedRoomPlans={onRoomPlansSelect}
                    setSelectedMeetingRooms={setDisplayedMeetingRooms}
                    setIsNeighborhoodFiltered={setIsNeighborhoodFiltered}
                    setIsTypeFiltered={setIsTypeFiltered}
                    setTypeFilter={setTypeFilter}
                    typeFilter={typeFilter}
                    defaultRoomConfig={defaultRoomConfig}
                    buildingEquipment={buildingEquipment}
                    filteredEquipment={filteredEquipment}
                    setFilteredEquipment={setFilteredEquipment}
                    selection={selection}
                    setSelection={setSelection}
                    meetingRoomMinCapacity={meetingRoomMinCapacity}
                    onSetMeetingRoomMinCapacity={setMeetingRoomMinCapacity}
                />

                <DayPickCalendarComponent selectedDate={date} setSelectedDate={setDate}/>

                {listAndMatrixLoading ? <Skeleton width={"100%"} height={"200px"}/> :
                    <MeetingRoomBookingMatrixComponent
                        {...ps.mtngRoomBkngMtrxProps}
                    />}
                <Divider/>
                {showDeletionDialog && <DeleteEntryConfirmationDialogComponent{...ps.DltEntryCofrmDlogProps}/>}


                {listAndMatrixLoading ? <Skeleton width={"100%"} height={"150px"}/> :
                    <ClickAwayListener onClickAway={handleOnClickAway}>
                        <div>
                            <MeetingRoomBookingManagerListComponent {...ps.bkngListProps}/>
                        </div>
                    </ClickAwayListener>}
            </DialogContent>


            <DialogActions className={isNoFullscreen ? "dialogActionsNoFullScreen" : "dialogActions"}>
                <CloseButton
                    style={{visibility: 'hidden', display: isNoFullscreen ? 'none' : 'flex'}}
                    size={isMobile ? "small" : "medium"}
                    data-testid={"btn-close"}>
                </CloseButton>
                <div style={{flex: "1 1 auto"}}></div>
                <Button
                    onTouchStart={(e) => {
                        e.stopPropagation();//don't trigger click away listener
                        startEditing();
                    }}
                    onClick={(e) => {
                        e.stopPropagation();//don't trigger click away listener
                        startEditing();
                    }}
                    color={"primary"}
                    size={isMobile ? "small" : "medium"}
                    variant={"contained"}
                    startIcon={!isMobile && <Edit/>}
                    disabled={!selectedBooking || !selectedBooking.managingAllowed}>
                    {t("edit_button-text")}
                </Button>
                <DeleteButton
                    disabled={!selectedBooking || !selectedBooking.managingAllowed}
                    onTouchStart={(e) => {
                        e.stopPropagation();//don't trigger click away listener
                        handleBookingDeletionAttempt();
                    }}
                    onClick={(e) => {
                        e.stopPropagation();//don't trigger click away listener
                        handleBookingDeletionAttempt();
                    }}
                    size={isMobile ? "small" : "medium"}
                    data-testid={"btn-delete-room-booking"}>
                    {t('delete')}
                </DeleteButton>
                <div style={{flex: "1 1 auto"}}></div>
                <CloseButton
                    {...ps.btnProps}
                    size={isMobile ? "small" : "medium"}
                    data-testid={"btn-close"}>
                </CloseButton>
            </DialogActions>

        </Dialog>
    </>
}

export default MeetingRoomBookingManagerComponent
