import React from 'react';
import {useTranslation} from 'react-i18next';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import CloseButton from "./Buttons/CloseButton";
import {useDeviceMediaType} from "../hooks/useDeviceMediaType";

export interface SupportDialogProps {
    showSupportDialog: boolean;
    setShowSupportDialog: (value: boolean) => void;
}

export const SupportDialog = ({showSupportDialog, setShowSupportDialog}: SupportDialogProps) => {
    const {t} = useTranslation();
    const {isNoFullscreen, isMobile} = useDeviceMediaType()

    const handleClose = () => {
        setShowSupportDialog(false);
    };

    return (
        <Dialog open={showSupportDialog} onClose={handleClose}>
            <DialogTitle>{t('support_dialog-title')}</DialogTitle>
            <DialogContent>
                {t('support_dialog-text')}{' '}
                <a href="mailto:sit2gether-support@materna.group">sit2gether-support@materna.group</a>
            </DialogContent>
            <DialogActions className={isNoFullscreen ? "dialogActionsNoFullScreen" : "dialogActions"}>
                <CloseButton
                    size={isMobile ? 'small' : 'medium'}
                    onClick={handleClose}
                    data-testid={"btn-close"}>
                </CloseButton>
            </DialogActions>
        </Dialog>
    );
};
