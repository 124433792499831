import React, {ChangeEvent, useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, TextField} from "@material-ui/core";
import "./HeaderComponent"
import {createBookingConfig as createConfigTemplate, updateBookingConfig} from "../graphql/mutations";
import {gql, useMutation, useQuery} from "@apollo/client";
import {getBookingConfig} from "../graphql/queries";
import RoomManagerAdminForm from "./RoomManager/RoomManagerAdminForm";
import {useMainApplicationContext} from "../hooks/useMainApplicationContext";
import {useErrorContext} from "../hooks/useErrorContext";
import {useTranslation} from "react-i18next";
import CloseButton from "./Buttons/CloseButton";
import {useDeviceMediaType} from "../hooks/useDeviceMediaType";

export interface BookingLimitProps {
    showBookingLimitations: boolean
    setShowBookingLimitations: (value: boolean) => void
}

const BookingLimitationComponent: React.FC<BookingLimitProps> = (props) => {

    let {showBookingLimitations, setShowBookingLimitations} = props
    const {selectedOrgUnit, currentUser, orgUnitList, rooms} = useMainApplicationContext()
    const {reportError} = useErrorContext()
    const [maxDaysInAdvance, setMaxDaysInAdvance] = useState<number | null>()
    const [maxBookableDays, setMaxBookableDays] = useState<number | null>()
    const [currentMaxDaysInAdvance, setCurrentMaxDaysInAdvance] = useState<number | null>()
    const [currentMaxBookableDays, setCurrentMaxBookableDays] = useState<number | null>()
    const [createBookingConfigMutation] = useMutation(gql(createConfigTemplate))
    const [updateBookingConfigMutation] = useMutation(gql(updateBookingConfig))

    const {data, refetch} = useQuery(gql(getBookingConfig), {
        variables: {
            orgUnitId: selectedOrgUnit?.orgId
        }
    })

    const useStyles = makeStyles({
            dialogContent: {
                "&.MuiDialogContent-root": {
                    display: "flex",
                    flexDirection: "column",
                },

            },
            textField: {
                "&.MuiFormControl-root": {
                    margin: "10px 0px",

                }
            },
            dialogActions: {
                alignSelf: "right",
                "&.MuiDialogActions-root": {
                    padding: "8px 23px",
                    justifyContent: "end",
                },
            },
        }
    )
    const classes = useStyles();

    const handleMaxDaysAdvance = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (!isNaN(+event.target.value) && (+event.target.value) <= 9999) {
            setMaxDaysInAdvance(+event.target.value)
        }
    }
    const handleMaxBookableDays = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (!isNaN(+event.target.value) && (+event.target.value) <= 9999) {
            setMaxBookableDays(+event.target.value)
        }
    }

    const handleCancel = () => {
        setShowBookingLimitations(false)
        setMaxDaysInAdvance(currentMaxDaysInAdvance)
        setMaxBookableDays(currentMaxBookableDays)
    }

    const handleBookingLimitation = () => {

        updateBookingConfigMutation({
            variables: {
                input: {
                    orgUnitId: selectedOrgUnit?.orgId,
                    orgUnitAdmin: "Admin-" + selectedOrgUnit?.orgId,
                    maxDaysInAdvance: maxDaysInAdvance,
                    maxBookableDays: maxBookableDays,
                }
            }

        }).then(() => setShowBookingLimitations(false))
            .then(() => {
                refetch().then()
                setCurrentMaxBookableDays(maxBookableDays)
                setCurrentMaxDaysInAdvance(maxDaysInAdvance)
            })
            .catch(() => createBookingConfigMutation({
                variables: {
                    input: {
                        orgUnitId: selectedOrgUnit?.orgId,
                        orgUnitAdmin: "Admin-" + selectedOrgUnit?.orgId,
                        maxDaysInAdvance: maxDaysInAdvance,
                        maxBookableDays: maxBookableDays,
                    }
                }
            }).then(() => {
                setShowBookingLimitations(false)
                refetch().then()
                setCurrentMaxBookableDays(maxBookableDays)
                setCurrentMaxDaysInAdvance(maxDaysInAdvance)
            })
                .catch(reason => reportError(reason, "", "BookingLimitationComponent handleBookingLimitation")))
    }

    useEffect(() => {
        if (selectedOrgUnit)
            refetch().then().catch((err) => reportError(err, "", "BookingLimitationComponent useEffect"))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrgUnit])

    useEffect(() => {
        if (data && data.getBookingConfig) {
            setCurrentMaxDaysInAdvance(data.getBookingConfig.maxDaysInAdvance)
            setCurrentMaxBookableDays(data.getBookingConfig.maxBookableDays)
            setMaxDaysInAdvance(data.getBookingConfig.maxDaysInAdvance)
            setMaxBookableDays(data.getBookingConfig.maxBookableDays)
        } else {

            setCurrentMaxDaysInAdvance(null)
            setCurrentMaxBookableDays(null)
            setMaxDaysInAdvance(null)
            setMaxBookableDays(null)

        }
    }, [data])

    const {t} = useTranslation();
    const {isNoFullscreen, isMobile} = useDeviceMediaType()
    return (
        <>
            <Dialog style={{width: "100%"}} open={showBookingLimitations}
                    data-testid={"bookingLimitaions-test-id"}>
                <DialogTitle>
                    {t("booking_limitation_dialog_title")}
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    {currentUser.isAdmin || currentUser.isOrgUnitAdmin ?
                        <RoomManagerAdminForm rooms={rooms}/>
                        :
                        <div data-testid={"org-unit-label"}>{t("daily_bookings_table_orgunit_column")}: <div
                            data-testid={"org-unit-label-content"}>{orgUnitList[0] && orgUnitList[0].orgName}</div>
                        </div>
                    }
                    <TextField className={classes.textField}
                               label={t('max_lead_time')}
                               variant="outlined"
                               value={(maxDaysInAdvance === 0 || maxDaysInAdvance) ? maxDaysInAdvance >= 0 ? maxDaysInAdvance : '' : ''}
                               onChange={handleMaxDaysAdvance}
                               data-testid={"max-advance-field-test-id"}
                    >
                    </TextField>
                    <TextField className={classes.textField}
                               label={t('max_bookable_days')}
                               variant="outlined"
                               value={(maxBookableDays === 0 || maxBookableDays) ? maxBookableDays >= 0 ? maxBookableDays : '' : ''}
                               onChange={handleMaxBookableDays}
                               data-testid={"max-bookable-field-test-id"}
                    ></TextField>

                </DialogContent>

                <DialogActions className={isNoFullscreen ? "dialogActionsNoFullScreen" : "dialogActions"}>
                    <CloseButton
                        size={isMobile ? 'small' : 'medium'}
                        style={{visibility: 'hidden', display: isNoFullscreen ? 'none' : 'flex'}}
                        data-testid={"btn-close-hidden"}
                    ></CloseButton>
                    <div style={{flex: "1 1 auto"}}></div>
                    <Button
                        size={isMobile ? 'small' : 'medium'}
                        onClick={handleBookingLimitation}
                        color={"primary"}
                        variant={"contained"}
                        disabled={isNaN(maxBookableDays?.valueOf()!) || isNaN(maxDaysInAdvance?.valueOf()!)}
                        data-testid={"create-btn"}
                    >
                        {t("button_save")}
                    </Button>
                    <div style={{flex: "1 1 auto"}}></div>
                    <CloseButton
                        size={isMobile ? 'small' : 'medium'}
                        onClick={handleCancel}
                        data-testid={"btn-close"}>
                    </CloseButton>

                </DialogActions>
            </Dialog>
        </>)

}

export default BookingLimitationComponent
