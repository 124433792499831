import React from "react";
import {Button, DialogActions} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import Sit2GetherCloseIcon from "../../styles/Sit2GetherCloseIcon";
import {useTranslation} from "react-i18next";
import {useDeviceMediaType} from "../../hooks/useDeviceMediaType";


interface Props {
    handleSave: () => void
    saveButtonDisabled: boolean,
    handleClose: () => void
}

// save and close buttons, adjusts to mobile device
export const EditSeatBookingDialogActions: React.FC<Props> = (props) => {
    const {handleSave, saveButtonDisabled, handleClose} = props;
    const {t} = useTranslation();
    const {isNoFullscreen, shouldAlignButtons} = useDeviceMediaType();
    return (
        <DialogActions className={isNoFullscreen ? "dialogActionsNoFullScreen" : "dialogActions"}>
            <Button
                onClick={handleSave}
                size={shouldAlignButtons ? "small" : "medium"}
                color="primary"
                variant="contained"
                disabled={saveButtonDisabled}
                startIcon={!shouldAlignButtons && <SaveIcon/>}>
                {t("button_save")}
            </Button>
            <Button
                onClick={handleClose}
                color="primary"
                size={shouldAlignButtons ? "small" : "medium"}
                variant="contained"
                disabled={false}
                startIcon={!shouldAlignButtons && <Sit2GetherCloseIcon/>}>
                {t("button_close")}
            </Button>
        </DialogActions>
    );
}
