import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {gql, useMutation} from "@apollo/client";
import {useMainApplicationContext} from "../hooks/useMainApplicationContext";
import {useErrorContext} from "../hooks/useErrorContext";
import {useDeviceMediaType} from "../hooks/useDeviceMediaType";
import {createSecureUserSettings, updateSecureUserSettings} from "../graphqlCustom/mutationsCustom";

export interface ConsentDialogProps {
    initialOpen: boolean
}

export const ConsentDialog = (consentDialogProps: ConsentDialogProps) => {
    const {isNoFullscreen} = useDeviceMediaType()
    const {initialOpen} = consentDialogProps;
    const {t} = useTranslation();
    const {currentUser, userSettingsObject} = useMainApplicationContext();
    const setShouldRefetchUserSettings = userSettingsObject?.setShouldRefetchUserSettings;
    const {reportError} = useErrorContext();

    const [updateSecureUserSettingsMutation] = useMutation(gql(updateSecureUserSettings))
    const [createSecureUserSettingsMutation] = useMutation(gql(createSecureUserSettings))


    function refetchUserSettings() {
        if (!!setShouldRefetchUserSettings) {
            setShouldRefetchUserSettings(prev => !prev);
        }
    }

    function updateUserConsent(newValue: boolean) {
        if (userSettingsObject?.userSettings) {
            updateSecureUserSettingsMutation({
                variables: {
                    input: {
                        userId: currentUser.ID,
                        consent: newValue
                    }
                }
            }).catch(error => reportError(error, "", "ConsentDialog updateUserSettingsMutation"))
                .finally(refetchUserSettings);
        } else {
            createSecureUserSettingsMutation({
                variables: {
                    input: {
                        userId: currentUser.ID,
                        userSettingsMailConfigId: currentUser.ID,
                        consent: newValue,
                        userSettingsDefaultRoomConfigId: currentUser.ID,
                    }
                }
            }).catch(error => reportError(error, "", "ConsentDialog createUserSettingsMutation"))
                .finally(refetchUserSettings);
        }


    }

    return <Dialog open={initialOpen}>
        <DialogTitle>
            {t("my_settings-consent_title")}
        </DialogTitle>
        <DialogContent>
            {t("my_settings-hint_text")}
        </DialogContent>
        <DialogActions className={isNoFullscreen ? "dialogActionsNoFullScreen" : "dialogActions"}>
            <Button variant={"contained"} color={"primary"} onClick={() => {
                updateUserConsent(false);
            }}>
                {t("my_settings-no_consent_text")}
            </Button>
            <Button variant={"contained"} color={"primary"} onClick={() => {
                updateUserConsent(true);
            }}>
                {t("my_settings-consent_text")}
            </Button>
        </DialogActions>
    </Dialog>
}
