import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Button from "@material-ui/core/Button"
import Collapse from "@material-ui/core/Collapse"
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import 'react-calendar/dist/Calendar.css';
import "../../styles/MultiBookingDialog.css";
import {DateObject} from "react-multi-date-picker";
import {
    getAllTimeBookingsForCurrentSeatAndDate,
    getGreatestBookableDate,
    handleLimitOfBookings,
    isDateSelected
} from "../MultiBookingDialog/MultiBookingDialogHelper";
import {Booking, Building, MeetingRoomBooking, Room} from "../../API";
import {useBookingBySeatId} from "../../hooks/useBookingBySeatId";
import {gql, useQuery} from "@apollo/client";
import {getSeatBookings} from "../../graphql/queries";
import MultiBookingLegendComponent, {
    MultiBookingLegendComponentProps
} from "../MultiBookingDialog/Legend/MultiBookingLegendComponent";
import {columnStyleFixedWidth, defaultOrgUnitId} from "../../Utils/commons";
import MultiBookingCalendarComponent, {
    MultiBookingCalendarComponentProps
} from "../MultiBookingDialog/MultiBookingCalendarComponent";
import MultiBookingDialogTitle, {MultiBookingDialogTitleProps} from "../MultiBookingDialog/MultiBookingDialogTitle";
import MultiBookingDialogNameField, {
    MultiBookingDialogNameFieldProps
} from "../MultiBookingDialog/MultiBookingDialogNameField";
import {createNewTodayDateWithoutHours, isGreaterThanDate, isLowerThanDate, toDateISO} from "../../services/DateUtils";
import MultiBookingDialogBookingButton, {
    MultiBookingDialogBookingButtonProps
} from "../MultiBookingDialog/MultiBookingDialogBookingButton";
import {BookingFor, MeetingRoomType} from "../../Utils/Enums";
import dayjs, {Dayjs} from "dayjs";
import duration from "dayjs/plugin/duration";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import TimeWindowTableComponent, {TimeWindowTableComponentProps} from "../MultiBookingDialog/TimeWindowTableComponent";
import customParseFormat from 'dayjs/plugin/customParseFormat'
import MultiBookingDialogContext from "../../context/MultiBookingDialogContext";
import {useTranslation} from "react-i18next";
import {BookingType} from "../../types/BookingType";
import {useBookingByMeetingRoomId} from "../../hooks/useBookingByMeetingRoomId";
import MultiBookingDialogDropdown from "../MultiBookingDialog/MultiBookingDialogDropdown";
import {useBookingLimitation} from "../../hooks/useBookingLimitation";
import {Box, Tab, Tabs} from "@mui/material";
import {useDeviceMediaType} from "../../hooks/useDeviceMediaType";
import {useMainApplicationContext} from "../../hooks/useMainApplicationContext";
import MeetingRoomBookingSettings, {SeatOptionI} from "../MultiBookingDialogMeetingRoom/MeetingRoomBookingSettings";
import useMeetingRoomBookingSettingsStorage from "../../hooks/useMeetingRoomBookingSettingsStorage";
import DoubleTimePicker from "../MultiBookingDialog/DoubleTimePicker";
import CloseButton from "../Buttons/CloseButton";

dayjs.extend(customParseFormat)
dayjs.extend(duration);

export interface BookingDialogProps {
    bookingType: BookingType
    handleClose: () => void
    dateToSelectInitially: Date
    bookerName: string
    bookerGivenName: string
    bookerFamilyName: string
    seatId: string
    room: Room
    building: Building | undefined;
    roomCap: number
    meetRoomType: MeetingRoomType
    selectedSeatIsBookedOnSelectedDay: boolean
    selectedSeatIsBookedByMeOnSelectedDay?: boolean
    bookingStartTime?: Dayjs
    bookingEndTime?: Dayjs
    bookingList: (Booking | MeetingRoomBooking)[] | [],
    seatConfNames?: string[]
    seatConfDaysInAdvance?: number
    seatConfImageId?: string;
}

export const BookingDialog = (bookingDialogProps: BookingDialogProps) => {
    const {
        bookingType,
        handleClose,
        dateToSelectInitially,
        bookerName,
        bookerGivenName,
        bookerFamilyName,
        seatId,
        room,
        building,
        selectedSeatIsBookedOnSelectedDay,
        selectedSeatIsBookedByMeOnSelectedDay,
        bookingList,
        bookingStartTime,
        bookingEndTime,
        roomCap,
        meetRoomType,
        seatConfNames,
        seatConfDaysInAdvance,
        seatConfImageId
    } = bookingDialogProps

    const {currentUser, userSettingsObject} = useMainApplicationContext();

    const mounted = useRef(false);
    const [bookable, setBookable] = useState(false);
    const [isSeatAlreadyTaken, setIsSeatAlreadyTaken] = useState(false);
    const [showBookingDidNotComplete, setShowBookingDidNotComplete] = useState(false);
    const [isBookingLimitInDaysReached, setIsBookingLimitInDaysReached] = useState(false)
    const [isParallelBookedByOtherUsers, setIsParallelBookedByOtherUsers] = useState(false);
    const [isGreaterThanGreatestBookableDate, setIsGreaterThanGreatestBookableDate] = useState(false);
    const [isMaxBookableDaysWithSelectionReached, setIsMaxBookableDaysWithSelectionReached] = useState(false)

    const [bookedDays, setBookedDays] = useState<Date[]>([])
    const [endTime, setEndTime] = useState<Dayjs | null>(bookingEndTime ?? getDefaultTimes()[1])
    const [beginTime, setBeginTime] = useState<Dayjs | null>(bookingStartTime ?? getDefaultTimes()[0])
    const [selectedDates, setSelectedDates] = useState<Date[]>([dateToSelectInitially])
    const [parallelBookedDatesByOtherUsers, setParallelBookedDatesByOtherUsers] = useState<Date[]>([]);
    const bookingConfig = useBookingLimitation(room.orgUnitId ?? "");
    const [greatestBookableDate, setGreatestBookableDate] = useState<Date>(getGreatestBookableDate(bookingConfig, bookingType));
    const [currentMonth, setCurrentMonth] = useState(new Date(dateToSelectInitially.getFullYear(), dateToSelectInitially.getMonth(), 1).toString())

    const [bookingForInputUsername, setBookingForInputUsername] = useState<string>("")

    const {meetingRoomBookingSettings, setMeetingRoomBookingSettings} = useMeetingRoomBookingSettingsStorage();

    const [bookingForState, setBookingForState] = useState(BookingFor.BOOKING_FOR_ME)

    const [seatConfigDaysInAdvance, setSeatConfigDaysInAdvance] = useState<number>(0);

    const maxParticipantNumber = roomCap;
    const today: Date = createNewTodayDateWithoutHours()
    const isSeatDialog = bookingType === 'seat';
    const isMeetingDialog = bookingType === 'meetingRoom';
    const isBookingFromMatrix = bookingStartTime && bookingEndTime;

    const bookingByMeetingRoomId = useBookingByMeetingRoomId(
        room.roomId, room.orgUnitId ?? defaultOrgUnitId, currentMonth, seatId)
    const bookingBySeatId = useBookingBySeatId(
        room.roomId, room.orgUnitId ?? defaultOrgUnitId, currentMonth, seatId)
    const mergedBookingList: (Booking | MeetingRoomBooking)[] = useMemo(() => {
        return isSeatDialog ? bookingBySeatId : bookingByMeetingRoomId
    }, [isSeatDialog, bookingByMeetingRoomId, bookingBySeatId])


    const {refetch: refetchBookingByBookerAndDate} = useQuery(gql(getSeatBookings), {})
    const MultiBookingDialogProviderValues = useMemo(() => ({
        currentMonth,
        setCurrentMonth
    }), [currentMonth, setCurrentMonth]);


    function isBeforeOrSame(bookingEnd: Dayjs, beginTime: Dayjs | null) {
        return (bookingEnd.isBefore(beginTime) || bookingEnd.isSame(beginTime))
    }

    function isAfterOrSame(bookingBegin: Dayjs, endTime: Dayjs | null) {
        return (bookingBegin.isAfter(endTime) || bookingBegin.isSame(endTime))
    }

    function getDefaultTimes() {
        const defaultStart = dayjs("08:00", "HH:mm").millisecond(0);
        const defaultEnd = dayjs("17:00", "HH:mm").millisecond(0);

        const seatBookings = bookingList
            .filter(booking => booking.__typename === "Booking")
            .map(booking => booking as Booking)
            .filter(booking => booking.seatId === seatId)

        const doesBookingOverlapWithDefaultTimes = (booking: Booking | MeetingRoomBooking) => {

            const timeBegin = dayjs(booking.timeBegin, "YYYY-MM-DDTHH:mm:ss.SSSZ").millisecond(0);
            const timeEnd = dayjs(booking.timeEnd, "YYYY-MM-DDTHH:mm:ss.SSSZ").millisecond(0);
            const dayDefaultStart = timeBegin.set('hour', 8);
            const dayDefaultEnd = timeEnd.set('hour', 17);

            return ((booking.timeBegin && booking.timeEnd) &&
                (
                    (isAfterOrSame(timeBegin, dayDefaultStart) && timeBegin.isBefore(dayDefaultEnd)) ||
                    (timeEnd.isAfter(dayDefaultStart) && isBeforeOrSame(timeEnd, dayDefaultEnd)) ||
                    (isAfterOrSame(timeEnd, dayDefaultEnd) && isBeforeOrSame(timeBegin, dayDefaultStart))
                ))
        }

        return seatBookings.some(doesBookingOverlapWithDefaultTimes) || bookingType === "meetingRoom" ? [null, null] : [defaultStart, defaultEnd];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }


    const [isInvalidBookingTime, setIsInvalidBookingTime] = useState(selectedSeatIsBookedOnSelectedDay);
    const [isInvalidBookingTimeEndTimeIsInPast, setIsInvalidBookingTimeEndTimeIsInPast] = useState(false);
    const handleStartTime = (newValue: Dayjs | null) => { // millisecond set to 0 because of possible bug in @mui/x-date-pickers
        (newValue != null) ? setBeginTime(newValue.millisecond(0)) : setBeginTime(newValue)
    }

    const handleEndTime = (newValue: Dayjs | null) => { // millisecond set to 0 because of possible bug in @mui/x-date-pickers
        (newValue != null) ? setEndTime(newValue.millisecond(0)) : setEndTime(newValue)
    }

    const isWithinSelectedTime = useCallback((booking: Booking | MeetingRoomBooking): boolean => {
        const bookingBegin: Dayjs = dayjs(booking.timeBegin)
        const bookingEnd: Dayjs = dayjs(booking.timeEnd)
        return !(isBeforeOrSame(bookingEnd, beginTime) || isAfterOrSame(bookingBegin, endTime))
    }, [beginTime, endTime])

    useEffect(function removeUnavailableButSelectedDates() {
        let arr = selectedDates.filter((date: Date) => isAnyTimeslotBookable(date))
        if (isMaxBookableDaysWithSelectionReached && !isDateBookedByCurrentUser(dateToSelectInitially))
            arr = arr.filter((date: Date) => date !== dateToSelectInitially)

        setSelectedDates(arr)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isBookingLimitInDaysReached,
        isGreaterThanGreatestBookableDate,
        isMaxBookableDaysWithSelectionReached,
        bookingConfig,
        mergedBookingList.length,
        parallelBookedDatesByOtherUsers
    ])

    useEffect(function setComponentMounted() {
        mounted.current = true; // Will set it to true on mount ...
        return () => {
            mounted.current = false;
        }; // ... and to false on unmount
    }, []);

    useEffect(function updateSelectedDatesIfDateIsStillBookable() {
        if (isAnyTimeslotBookable(dateToSelectInitially) || selectedSeatIsBookedByMeOnSelectedDay)
            setSelectedDates([dateToSelectInitially])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(function checkIsSeatAlreadyTakenOnComponentLoad() {
        setIsSeatAlreadyTaken(selectedSeatIsBookedOnSelectedDay && !selectedSeatIsBookedByMeOnSelectedDay)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(function updateMyBookedDaysOnLoad() {
        if (mounted.current)
            updateMyBookedDays().then((response) => {
                if (bookingConfig) {
                    handleLimitOfDaysInAdvance()
                    setIsMaxBookableDaysWithSelectionReached(handleLimitOfBookings(false, response, selectedDates, bookingConfig, bookingType))
                    setIsBookingLimitInDaysReached(handleLimitOfBookings(true, response, selectedDates, bookingConfig, bookingType))
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(function removeBookedSeat() {
        if (bookingConfig) {
            handleLimitOfDaysInAdvance()
            setIsMaxBookableDaysWithSelectionReached(handleLimitOfBookings(false, bookedDays, selectedDates, bookingConfig, bookingType))
            setIsBookingLimitInDaysReached(handleLimitOfBookings(true, bookedDays, selectedDates, bookingConfig, bookingType))
        }
        setIsSeatAlreadyTaken(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateToSelectInitially, selectedDates])

    const updateMyBookedDays = useCallback(async () => {
        const bookerId = currentUser.ID;
        const bookingsByBooker = await refetchBookingByBookerAndDate({
            input: {
                bookerId: bookerId,
                roomId: room.roomId,
                typeOfQuery: "bookingsByBookerAndDateAndRoom",
                date: toDateISO(today)
            }
        })
        let mapOfBookedDays = bookingsByBooker.data.getSeatBookings.items
            .map((item: any) => item.date)
            .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
            .map((value: string) => new Date(value))

        setBookedDays([...mapOfBookedDays])

        return [...mapOfBookedDays]
    }, [greatestBookableDate, refetchBookingByBookerAndDate, today])

    const getBookingsForDate = useCallback((date: Date): (Booking | MeetingRoomBooking)[] => {
        return mergedBookingList.filter((booking: Booking | MeetingRoomBooking) => {
            return booking.date === toDateISO(date) && isWithinSelectedTime(booking)
        })
    }, [mergedBookingList, isWithinSelectedTime])

    const isDateBookedByCurrentUser = useCallback((date: Date): boolean => {
        const bookings = getBookingsForDate(date)
        if (!bookings || bookings.length === 0) return false
        return bookings.some((booking: Booking | MeetingRoomBooking) => booking.bookerId === currentUser.ID);
    }, [getBookingsForDate])

    const isDateBookedByAnotherUser = useCallback((date: Date): boolean => {
        const bookings = getBookingsForDate(date)
        if (!bookings || bookings.length === 0) return false
        return bookings.some((booking: Booking | MeetingRoomBooking) => booking.bookerId !== currentUser.ID)
    }, [getBookingsForDate])

    function isSameMeetRoomOrSeat(item: Booking | MeetingRoomBooking, itemId: string) {
        return item.__typename === "Booking" ? item.seatId === itemId : item.meetingRoomId === itemId
    }

    const isAnyTimeslotBookable = useCallback((d: Date): boolean => {
        let bookingListForDate = mergedBookingList
            .filter(booking => booking.date === toDateISO(d) && isSameMeetRoomOrSeat(booking, seatId));

        let availableMinutesInDay = dayjs.duration({hours: 24}).asMinutes() - 1;

        bookingListForDate.forEach((booking: Booking | MeetingRoomBooking) => {
            const bookingDuration = dayjs(booking.timeEnd).diff(dayjs(booking.timeBegin), "minutes");
            availableMinutesInDay = availableMinutesInDay - bookingDuration;
        })

        return availableMinutesInDay !== 0;
    }, [mergedBookingList, seatId])

    useEffect(function bookingConfigHasChanged() {
        if (bookingConfig) {
            setGreatestBookableDate(getGreatestBookableDate(bookingConfig, bookingType))
            handleLimitOfDaysInAdvance()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingConfig.maxDaysInAdvance, dateToSelectInitially, bookingType])

    useEffect(function updateMyBookedDaysBySeatId() {
        if (bookingConfig && mounted.current) {
            updateMyBookedDays()
                .catch(error => {
                    console.error("Error in updateMyBookedDaysBySeatId", error)
                })
            setIsMaxBookableDaysWithSelectionReached(handleLimitOfBookings(false, bookedDays, selectedDates, bookingConfig, bookingType))
            setIsBookingLimitInDaysReached(handleLimitOfBookings(true, bookedDays, selectedDates, bookingConfig, bookingType))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingConfig.maxBookableDays, mergedBookingList.length, selectedDates])

    useEffect(function showBookingDidNotCompleteAlert() {
        setTimeout(() => {
            if (mounted.current)
                setShowBookingDidNotComplete(false);
        }, 7000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showBookingDidNotComplete]);

    useEffect(() => {
        async function updateSeatAlreadyTakenAndParallelBookDates() {

            setIsSeatAlreadyTaken(selectedDates.some(value => getBookingsForDate(value) && !isDateBookedByCurrentUser(value) && selectedSeatIsBookedOnSelectedDay))
            setParallelBookedDatesByOtherUsers(selectedDates.filter((date) => isDateBookedByAnotherUser(date)))
        }

        if (isSeatDialog) {
            updateSeatAlreadyTakenAndParallelBookDates().then(() => {
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSeatDialog, mergedBookingList.length])

    useEffect(function updateIsParallelBookedByOtherUsers() {
        setIsParallelBookedByOtherUsers(parallelBookedDatesByOtherUsers.length > 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parallelBookedDatesByOtherUsers])

    function getErrorMsgSeatParallelBooked(): string {
        let errorMsg = ""
        let dates = parallelBookedDatesByOtherUsers.map(date => date.toLocaleDateString(t("jsLocalDateProperty"), {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        })).join(", ")
        if (parallelBookedDatesByOtherUsers.length > 1) {
            errorMsg += (t("multibookingdialog_meetingroom_error_parallel_bookings")).replace("%s", dates)
        } else {
            errorMsg += (t("multibookingdialog_meetingroom_error_parallel_booking")).replace("%s", dates)
        }
        return errorMsg
    }

    function getAlertContent() {
        if (showBookingDidNotComplete) {
            return t("multibookingdialog_error_booking_not_complete")
        } else if (isInvalidBookingTimeEndTimeIsInPast) {
            return t("multibookingdialog_error_time_slot_not_bookable_endtime_is_in_past")
        } else if (isGreaterThanGreatestBookableDate) {
            return t("multibookingdialog_error_max_booking_days_exceeded")
                + greatestBookableDate.toLocaleDateString('de-DE', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                }) + "."
        } else if (isMaxBookableDaysWithSelectionReached) {
            return t("multibookingdialog_meetingroom_error_max_bookable_days_")
        } else if (selectedSeatIsBookedOnSelectedDay) {
            return t("multibookingdialog_error_time_slot_not_bookable")
        } else if (isSeatAlreadyTaken) {
            return t("multibookingdialog_meetingroom_error_seat_already_taken")
            //Only show invalid booking time error if the time input fields are complete
        } else if (isInvalidBookingTime && isBookingTimeInputComplete()) {
            return t("multibookingdialog_error_time_slot_not_bookable")
        } else if (isParallelBookedByOtherUsers) {
            return getErrorMsgSeatParallelBooked()
        }
    }

    function isDateBookableForMe(date: Date): boolean {
        if (isLowerThanDate(date, today)) {
            return false;
        }
        if (isGreaterThanDate(date, greatestBookableDate)) {
            return false;
        }
        if (isBookingLimitInDaysReached) {
            return isThereAnotherBookingOnThatDayByMe(date)
        }
        if (isMaxBookableDaysWithSelectionReached) {
            if (isDateSelected(date, selectedDates)) {
                return !inputConflictsWithBookingsOnDay(date);
            } else {
                const dateIsBooked = bookedDays.map(d => toDateISO(d)).some((_dateStr) => _dateStr === toDateISO(date))
                return dateIsBooked && isAnyTimeslotBookable(date);
            }
        }
        if (!isAnyTimeslotBookable(date))
            return false;

        return !inputConflictsWithBookingsOnDay(date);
    }

    function isThereAnotherBookingOnThatDayByMe(date: Date): boolean {
        return bookedDays.map(d => toDateISO(d)).indexOf(toDateISO(date)) !== -1;
    }

    function handleClickOnTimeWindow(start: Dayjs, end: Dayjs) {
        setBeginTime(start);
        setEndTime(end);
    }

    function handleOnCalendarChange(dates: any) {
        setSelectedDates(dates.map((d: DateObject) => new Date(d.toString())))
    }

    function handleLimitOfDaysInAdvance(): void {
        if (bookingType === "meetingRoom") {
            setIsGreaterThanGreatestBookableDate(false);
        } else if (bookingConfig.maxDaysInAdvance === 0) {
            setIsGreaterThanGreatestBookableDate(false)
        } else if (isGreaterThanDate(dateToSelectInitially, greatestBookableDate) && selectedDates.length === 0) {
            setIsGreaterThanGreatestBookableDate(true)
        } else if (!selectedDates?.length) {
            setIsGreaterThanGreatestBookableDate(false)
        } else {
            const sortedDays = [...selectedDates].sort((a, b) => {
                return a.getTime() - b.getTime();
            });
            const currentLargestDate = sortedDays[sortedDays.length - 1];
            const dayInMs = 24 * 60 * 60 * 1000;
            if (currentLargestDate.getTime() - dayInMs >= today.getTime() + (bookingConfig.maxDaysInAdvance * dayInMs)) {
                setIsGreaterThanGreatestBookableDate(true);
            } else {
                setIsGreaterThanGreatestBookableDate(false);
            }
        }
    }

    function bookingTimeIntervalIsValid(inputStart: string, inputEnd: string,
                                        existingBookingStart: string, existingBookingEnd: string): boolean {

        if (!isBookingTimeInputComplete() || !isBookingTimeFormatValid()) {
            return false
        }

        return inputStart >= existingBookingEnd || inputEnd <= existingBookingStart
    }

    const inputConflictsWithBookingsOnDay = useCallback((date: Date): boolean => {
        const allTimeBookingsForCurrentSeat = getAllTimeBookingsForCurrentSeatAndDate(mergedBookingList, seatId, room, toDateISO(date));
        return allTimeBookingsForCurrentSeat.some((booking: Booking | MeetingRoomBooking) =>
            !bookingTimeIntervalIsValid(
                (beginTime?.format("HH:mm") ?? ""),
                (endTime?.format("HH:mm") ?? ""),
                dayjs(booking.timeBegin).format("HH:mm"),
                dayjs(booking.timeEnd).format("HH:mm")
            ))

    }, [beginTime, mergedBookingList, endTime, room, seatId])

    const inputEndTimeIsInPast = useCallback((endTime: Dayjs, selectedDates: Date[]): boolean => {
        selectedDates.sort((a, b) => a.getTime() - b.getTime());
        const today = new Date();
        const firstDay = selectedDates[0]
        firstDay.setHours(endTime.hour(), endTime.minute(), 0, 0);

        return firstDay.getTime() < today.getTime();
    }, [mergedBookingList, endTime, room, seatId])

    //Only returns true if both time picker input fields are filled in
    const isBookingTimeInputComplete = useCallback(() => {
        return !!(beginTime?.isValid() && endTime?.isValid())
    }, [beginTime, endTime])

    const isBookingTimeFormatValid = useCallback(() => {
        return !!(endTime?.isAfter(beginTime));
    }, [beginTime, endTime])

    useEffect(function updateIsButtonDisabled() {
        // The bookable state is conceptually wrong because it is missing the validation of time inputs
        // and doesn't differentiate between seat and room booking.
        // Unfortunately, no time for me now to refactor it.

        if (isMeetingDialog && !areRequirementsFulfilled()) setBookable(false)
        else setBookable(true)

        function areRequirementsFulfilled() {
            const isValidNumberOfParticipants = meetingRoomBookingSettings.participantNumber !== null
                && Number.isInteger(meetingRoomBookingSettings.participantNumber)
                && meetingRoomBookingSettings.participantNumber >= 0;
            const isMeetingInfoValid = meetingRoomBookingSettings.meetingType?.length > 0
                && meetingRoomBookingSettings.meetingName?.trim().length > 0;
            return isValidNumberOfParticipants && isMeetingInfoValid;
        }
    }, [isMeetingDialog, meetingRoomBookingSettings]);


    useEffect(() => {

        if (selectedDates.length === 0) {
            setIsInvalidBookingTime(false)
        }

        if (selectedDates.length > 0) {
            setIsParallelBookedByOtherUsers(false)
        }

        if (isMeetingDialog || room.isTimeActive) {

            if (!isBookingTimeInputComplete() || !isBookingTimeFormatValid()) {
                setIsInvalidBookingTime(true)
                return;
            }

            const inputConflictsWithAnyBookingsOnSelectedDates
                = selectedDates.some((date: Date) => inputConflictsWithBookingsOnDay(date))

            setIsInvalidBookingTime(inputConflictsWithAnyBookingsOnSelectedDates)

            if (endTime && selectedDates && selectedDates.length > 0) {
                setIsInvalidBookingTimeEndTimeIsInPast(inputEndTimeIsInPast(endTime, selectedDates))
            } else {
                setIsInvalidBookingTimeEndTimeIsInPast(false)
            }
        }
    }, [beginTime, endTime, selectedDates, bookingBySeatId, dateToSelectInitially, room, bookingList, seatId, isAnyTimeslotBookable, inputConflictsWithBookingsOnDay, inputEndTimeIsInPast, isBookingTimeFormatValid, isBookingTimeInputComplete, isMeetingDialog])

    const {t} = useTranslation();
    const collapseIn = showBookingDidNotComplete ||
        (isSeatAlreadyTaken) ||
        isGreaterThanGreatestBookableDate ||
        isMaxBookableDaysWithSelectionReached ||
        (isParallelBookedByOtherUsers) ||
        (isInvalidBookingTime && isBookingTimeInputComplete()) ||
        isInvalidBookingTimeEndTimeIsInPast;

    interface ChildrenProps {
        multiBookingDialogTitleProps: MultiBookingDialogTitleProps,
        multiBookingDialogBookingButtonProps: MultiBookingDialogBookingButtonProps,
        multiBookingLegendComponentProps: MultiBookingLegendComponentProps,
        multiBookingCalenderComponentProps: MultiBookingCalendarComponentProps,
        timeWindowTableComponentProps: TimeWindowTableComponentProps,
        multiBookingDialogNameFieldProps: MultiBookingDialogNameFieldProps,
    }

    const multiBookingDialogBookingButtonProps: MultiBookingDialogBookingButtonProps = {
        bookingType: bookingType,
        meetingRoomProps: {
            meetingName: meetingRoomBookingSettings.meetingName,
            meetingType: meetingRoomBookingSettings.meetingType,
            numberOfParticipants: meetingRoomBookingSettings.participantNumber ?? "",
            maxParticipantNumber: maxParticipantNumber,
            visitors: meetingRoomBookingSettings.visitors,
            meetRoomType: meetRoomType,
            seatConf: meetingRoomBookingSettings.seatConf
        },
        shouldDisabled: !bookable,
        handleClose: handleClose,
        bookingConfig: bookingConfig,
        bookerName: bookerName,
        bookerGivenName: bookerGivenName,
        bookerFamilyName: bookerFamilyName,
        seatId: seatId,
        room: room,
        selectedDates: selectedDates,
        setSelectedDates: setSelectedDates,
        isDateBookableForMe: isDateBookableForMe,
        bookedDays: bookedDays,
        bookingForState: bookingForState,
        bookingForInputUsername: bookingForInputUsername,
        setShowBookingDidNotComplete: setShowBookingDidNotComplete,
        beginTime: (beginTime?.format("HH:mm") ?? ""),
        endTime: (endTime?.format("HH:mm") ?? ""),
        setIsInvalidBookingTime: setIsInvalidBookingTime,
        isInvalidBookingTime: isInvalidBookingTime,
        isInvalidBookingTimeEndTimeIsInPast: isInvalidBookingTimeEndTimeIsInPast
    }

    const cProps: ChildrenProps = {
        multiBookingDialogTitleProps: {
            bookingType: bookingType,
            room: room,
            seatId: seatId,
            building: building,
        },
        multiBookingDialogBookingButtonProps: multiBookingDialogBookingButtonProps,
        multiBookingLegendComponentProps: {
            bookingType: bookingType,
            isTimeActive: room.isTimeActive === true || isMeetingDialog,
            isBookingTimeFormatValid: isBookingTimeFormatValid,
        },
        multiBookingCalenderComponentProps: {
            bookingType: bookingType,
            today: today,
            selectedDates: selectedDates,
            onCalendarSelectionChange: handleOnCalendarChange,
            bookings: mergedBookingList,
            greatestBookableDate: greatestBookableDate,
            isBookingLimitReached: isMaxBookableDaysWithSelectionReached,
            isTimeActive: room.isTimeActive === true || isMeetingDialog,
            isDateBookableForMe: isDateBookableForMe,
            isAnyTimeslotBookable: isAnyTimeslotBookable,
            isBookingTimeFormatValid: isBookingTimeFormatValid,
            isBookingTimeInputComplete: isBookingTimeInputComplete,
            daysInAdvance: seatConfigDaysInAdvance
        },
        timeWindowTableComponentProps: {
            handleClickOnTimeWindow: handleClickOnTimeWindow,
            bookings: mergedBookingList,
            selectedDates: selectedDates,
        },
        multiBookingDialogNameFieldProps: {
            bookingForInputUsername: bookingForInputUsername,
            setBookingForInputUsername: setBookingForInputUsername,
        },
    }

    function getTimeInputs() {
        return room.isTimeActive || isMeetingDialog
            ? (
                <div className={"leftSideChild"} style={{display: "flex"}}>
                    <DoubleTimePicker
                        startTime={beginTime}
                        onChangeStartTime={handleStartTime}
                        endTime={endTime}
                        onChangeEndTime={handleEndTime}/>
                </div>
            )
            : (
                <></>
            )
    }

    function getMeetingRoomSettings() {
        return (
            <>
                {isMeetingDialog && <>
                    <div style={columnStyleFixedWidth}>
                        <MeetingRoomBookingSettings roomId={room.roomId}
                                                    meetingRoomId={seatId}
                                                    settings={meetingRoomBookingSettings}
                                                    setSettings={setMeetingRoomBookingSettings}
                                                    maxParticipants={maxParticipantNumber}
                                                    meetingRoomType={meetRoomType}
                                                    selectedDates={selectedDates}
                                                    allSeatConfigurations={bookingDialogProps.seatConfNames}
                                                    daysInAdvance={bookingDialogProps.seatConfDaysInAdvance}
                                                    seatConfigImageId={bookingDialogProps.seatConfImageId}
                                                    setSeatConfigDaysInAdvance={setSeatConfigDaysInAdvance}
                        />
                    </div>
                </>}
            </>
        )
    }

    function getTimeWindow() {
        return room.isTimeActive || isMeetingDialog
            ? (
                <div style={columnStyleFixedWidth}>
                    <TimeWindowTableComponent {...cProps.timeWindowTableComponentProps}/>
                </div>
            )
            : (
                <></>
            )
    }

    function getBookForDropdown() {
        return isSeatDialog
            ? (
                <MultiBookingDialogDropdown
                    bookingForState={bookingForState}
                    setBookingForState={setBookingForState}
                />
            )
            : (
                <></>
            )
    }

    function getCalendar() {
        return (
            <div style={columnStyleFixedWidth}>
                <MultiBookingCalendarComponent{...cProps.multiBookingCalenderComponentProps}/>
                {getTimeInputs()}
                {(userSettingsObject?.userSettings?.consent) && getBookForDropdown()}
                {(bookingForState === BookingFor.BOOKING_FOR_OTHERS) &&
                    <MultiBookingDialogNameField {...cProps.multiBookingDialogNameFieldProps}/>}
            </div>
        );
    }

    function getCalendarLegend() {
        return (
            <div style={columnStyleFixedWidth}>
                <MultiBookingLegendComponent{...cProps.multiBookingLegendComponentProps}/>
            </div>
        )
    }

    const [currentMobileTab, setCurrentMobileTab] = useState(() => {
        if (isBookingFromMatrix) {
            return 0;
        }
        return 1;
    });
    const {isNoFullscreen, isSmallMobile, isMobile} = useDeviceMediaType();

    const handleMobileTabChange = (event: React.SyntheticEvent, newVal: number) => {
        setCurrentMobileTab(newVal);
    }

    const shouldRenderTab = (value: number) => {
        if (!isNoFullscreen) {
            return true;
        }
        return value === currentMobileTab;
    }

    function getMobileBookingDialogContent() {
        return (
            <>
                <Box className={"dialogTab"} sx={{
                    borderBottom: 1,
                    borderColor: 'divider'
                }}>
                    <Tabs value={currentMobileTab} onChange={handleMobileTabChange}
                          variant={"scrollable"} scrollButtons={true} allowScrollButtonsMobile={true}>
                        <Tab label={t("multibookingdialog_legend_title")}/>
                        <Tab label={t("multibookingdialog_calendar_title")}/>
                        {isMeetingDialog && <Tab label={t('multibookingdialog_meetingroom_settings_title')}/>}
                        {(room.isTimeActive || isMeetingDialog) &&
                            <Tab label={t("multibookingdialog_time_window_title")}/>}
                    </Tabs>
                </Box>
                <Box className={"dialogTab"}>

                    {shouldRenderTab(0) && getCalendarLegend()}

                    {shouldRenderTab(1) && getCalendar()}

                    {isMeetingDialog && shouldRenderTab(2) && getMeetingRoomSettings()}

                    {((isMeetingDialog && shouldRenderTab(3)) || (!isMeetingDialog && room.isTimeActive && shouldRenderTab(2)))
                        && getTimeWindow()}
                </Box>
            </>
        );
    }

    function getDesktopBookingDialogContent() {
        return (
            <Box className={"dialogTab"} data-testid={"multibooking-calendar-and-legend"}>

                {getCalendarLegend()}

                <div className={"verticalLine"}/>
                {getCalendar()}

                {isMeetingDialog ? (<div className={"verticalLine"}/>) : (<></>)}
                {isMeetingDialog && getMeetingRoomSettings()}

                {(isMeetingDialog || room.isTimeActive) ? (<div className={"verticalLine"}/>) : (<></>)}
                {(isMeetingDialog || room.isTimeActive) && getTimeWindow()}
            </Box>
        );
    }

    function getBookingDialog() {
        return (
            <>
                <MultiBookingDialogTitle {...cProps.multiBookingDialogTitleProps}/>

                <DialogContent>
                    {isNoFullscreen ? getMobileBookingDialogContent() : getDesktopBookingDialogContent()}
                </DialogContent>

                <DialogActions className={isNoFullscreen ? "dialogActionsNoFullScreen" : "dialogActions"}>
                    <CloseButton
                        style={{visibility: 'hidden', display: isNoFullscreen ? 'none' : 'flex'}}
                        data-testid={"close-btn-hidden"}
                    ></CloseButton>
                    <div style={{flex: "1 1 auto"}}></div>
                    <MultiBookingDialogBookingButton{...cProps.multiBookingDialogBookingButtonProps}/>
                    <div style={{flex: "1 1 auto"}}></div>
                    <CloseButton
                        size={isMobile ? 'small' : 'medium'}
                        onClick={() => handleClose()}
                        data-testid={"close-btn"}>
                    </CloseButton>
                </DialogActions>
            </>
        )
    }

    function getBoxClass() {
        if (isSmallMobile) {
            return "dialogBoxSmallMobile";
        } else if (isNoFullscreen) {
            return "dialogBoxMobile";
        } else {
            return "dialogBox";
        }
    }

    return (
        <div className={`${getBoxClass()}`} data-testid={"multi-booking-dialog"}>
            <MultiBookingDialogContext.Provider value={MultiBookingDialogProviderValues}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"de"}>
                    <Collapse data-testid={"alerts"} in={collapseIn}>
                        <Alert severity="error" className={"alertStyle"}>
                            {showBookingDidNotComplete &&
                                <AlertTitle className={"alertTitleStyle"}>{t("booking-error_title")}</AlertTitle>}
                            {getAlertContent()}
                        </Alert>
                    </Collapse>
                    {getBookingDialog()}
                </LocalizationProvider>
            </MultiBookingDialogContext.Provider>
        </div>
    )
}
